<template>
  <div>
    <div class="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
      <div class="w-full md:w-3/5 py-6 text-center">
        <img class="w-full md:w-4/5 z-50" :src="require('@/assets/site/about-main.jpg')" />
      </div>
      <div class="flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left">
        <p class="uppercase tracking-loose w-full">WHO ARE WE?</p>
        <h1 class="my-4 text-3xl font-bold">
          AlphaSoftHub
        </h1>
        <h3 class="my-1 text-2xl font-italic">
          The Alpha of Software Development
        </h3>
        <p class="mb-4 my-2">
          Our aim is to provide the world with best service at a reasonable cost.
        </p>
      </div>
    </div>

    <div class="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
      <div class="flex flex-col w-full md:w-2/5 justify-center text-center ">
        <h3 class="my-1 text-center font-bold text-purple-600">
          WHY CHOOSE US
        </h3>
        <p class="mb-4 my-2 text-center">
          We are a young team with enormous potential to make the world a better place. We believe in making things easier for others and automating tasks to save time and cost.
        </p>
      </div>
      <div class="w-full md:w-3/5 py-6 text-center">
        <img class="w-full md:w-4/5 z-50" :src="require('@/assets/site/about-last.png')" />
      </div>
    </div>
  </div>
</template>
